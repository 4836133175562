import React from 'react';
import { Helmet } from 'react-helmet';
import Spline from '@splinetool/react-spline';

function App() {
  return (
    <>
      <Helmet>
        <title>parham noura</title>
      </Helmet>
      <div style={styles.container}>
        <Spline scene="https://prod.spline.design/8e1f7c4qy9AbGZ42/scene.splinecode" />
      </div>
    </>
  );
}

const styles = {
  container: {
    width: '100vw',
    height: '100vh',
    margin: 0,
    padding: 0,
    overflow: 'hidden',
  },
};

export default App;
